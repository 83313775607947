import { parseSize } from './parseAdSlotSize'

export const filterAdSizes = (
    sizes: [number, number][],
    maxAdWidth?: number | string | null,
    maxAdHeight?: number | string | null
) => {
    if (!sizes) {
        return null
    }
    const maxWidthParsed = parseSize(maxAdWidth) as number
    const maxHeightParsed = parseSize(maxAdHeight) as number
    if (!maxWidthParsed && !maxHeightParsed) {
        return sizes
    }
    return sizes?.filter(
        dimensions =>
            !!dimensions?.[0] && // has width
            (!maxWidthParsed || dimensions[0] <= maxWidthParsed) && // AND (maxWidth does not exist OR is bigger than the ad width)
            dimensions?.[1] && // AND has height
            (!maxHeightParsed || dimensions[1] <= maxHeightParsed) // AND (maxHeight does not exist OR is bigger than the ad height)
    )
}
