/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { filterAdSizes } from './filterAdSizes'

const stringToDimensions = (stringDim: string): number | number[] => {
    const parsed = (stringDim
        .split('x')
        .map(d => d && parseInt(d.toString(), 10))
        .filter(n => Number.isInteger(n)) || []) as number[]
    return parsed.length === 1 ? parsed[0] : parsed
}

export const parseSize = (size: any): number | number[] | null => {
    if (typeof size === 'number') {
        return size
    }
    if (!size || typeof size === 'undefined') {
        return null
    }
    if (typeof size === 'string') {
        return stringToDimensions(size)
    }
    return null
}

const parseSingleDimension = (dimension: any): null | [number, number] => {
    if (!dimension) {
        return null
    }
    if (Array.isArray(dimension)) {
        return (dimension?.map(parseSize)?.filter(Boolean) || []) as [number, number]
    }
    return parseSize(dimension) as [number, number]
}
const validateDimensions = (dimension: [number, number] | null) =>
    Boolean(dimension) && Array.isArray(dimension) && dimension.length > 0
const parseAdSlotSize = (
    sizes: any[] | string,
    maxAdWidth?: string | number | null,
    maxAdHeight?: string | number | null
) => {
    let normalizedSizes = [] as [number, number][]
    if (Array.isArray(sizes)) {
        normalizedSizes = sizes.map(parseSingleDimension).filter(validateDimensions) as [number, number][]
    } else if (typeof sizes === 'string') {
        const trimmed = sizes.trim()
        try {
            if (/^\[( *?)(\[|"]).*("|\])( *?)\]$/.exec(trimmed)) {
                const parsed = JSON.parse(trimmed)
                normalizedSizes = parsed.map(parseSingleDimension).filter(validateDimensions)
            } else {
                normalizedSizes = trimmed
                    .split(/(,{1} {0,1}|,{0,1} {1})/gim)
                    .map(parseSingleDimension)
                    .filter(validateDimensions) as [number, number][]
            }
        } catch (err) {
            // no need
        }
    }

    if (!maxAdWidth && !maxAdHeight) {
        return normalizedSizes
    }
    return filterAdSizes(normalizedSizes, maxAdWidth, maxAdHeight)
}
export default parseAdSlotSize
