/* eslint-disable @typescript-eslint/naming-convention */
import PropTypes, { Requireable } from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FilledSlot } from '../../../context/ads/filled.context'
import { useCustomFilledStateDispatch } from '../../../dispatchers/ads'
import { AdZoneName, DeviceType, FullSlotConfig, useSlotConfigs } from '../../../hooks/ads/useSlotConfigs'
import { useRouteChangeStart } from '../../../hooks/useRouteChange'
import { MergeTypes } from '../../../types/helpers/MergeTypes'
import { withErrorBoundary } from '../../ErrorBoundary/ErrorBoundary.component'
import SlidingWrapper from '../../SlidingWrapper/SlidingWrapper.component'
import CombinedSlotStyled from './CombinedSlot.style'
import Slot, { sharedSlotDefaultProps, sharedSlotPropTypes } from './Slot.component'
import { toRem } from '@hmn/rtl-web-core/helpers/theme'
import { usePersistentQueryParamValue } from '@hmn/rtl-web-core/hooks/usePersistentQueryParam'
import { AdPosition, AdPositionLoaded } from '@hmn/rtl-web-core/types/ads/Ad.interface'
import clsx from 'clsx'
// import { useSlotConfigsLegacy } from './useSlotConfigLegacy'

const useValueAsArray = (value: any) =>
    useMemo(() => (value ? (Array.isArray(value) ? value : [value]).filter(Boolean) : []), [value]) ? value : [value]

const toArrayPropType = (type: Requireable<any>) => PropTypes.oneOfType([type, PropTypes.arrayOf(type)])
const propTypes = {
    ...sharedSlotPropTypes,
    onFilledChange: PropTypes.func,
    title: PropTypes.string,
    position: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    divId: toArrayPropType(PropTypes.string),
    isSliding: PropTypes.bool,
    slidingProps: PropTypes.oneOfType([() => null, PropTypes.object]),
    inColumn: PropTypes.bool,
    bannerKey: PropTypes.string,
    minimumFixedHeightMobile: PropTypes.number,
    minimumFixedHeightDesktop: PropTypes.number,
    disablePlaceholder: PropTypes.bool,
    disableFixedHeight: PropTypes.bool,
    PlaceholderComponent: PropTypes.elementType,
    placeholderProps: PropTypes.shape({}),
    renderOutOfPage: PropTypes.bool,
    deviceProp: PropTypes.string,
    mobileOnly: PropTypes.bool,
    desktopOnly: PropTypes.bool
}

export type CombinedSlotProps = MergeTypes<
    PropTypes.InferProps<typeof propTypes>,
    {
        position: AdZoneName
        deviceProp?: DeviceType
    }
>

const useLargestAdHeight = () => {
    const [currentLoadedHeight, setCurrentLoadedHeight] = useState<number>(0)
    const [largestLoadedHeight, setLargestAdHeight] = useState<number>(0)
    const addLoadedAd = useCallback((ad: FilledSlot) => {
        if (!ad.filled || !ad.size?.[1]) return
        const newHeight = ad.size?.[1]
        setLargestAdHeight(previousHeight => (previousHeight >= newHeight ? previousHeight : newHeight))
        setCurrentLoadedHeight(previousHeight => (previousHeight === newHeight ? previousHeight : newHeight))
    }, [])

    return [currentLoadedHeight, largestLoadedHeight, addLoadedAd] as const
}

const CombinedSlot = ({
    position,
    inColumn,
    PlaceholderComponent,
    mobileOnly,
    desktopOnly,
    renderOutOfPage,
    deviceProp = 'any',
    title,
    isSliding,
    slidingProps,
    placeholderProps,
    onFilledChange,
    bannerKey,
    disableFixedHeight,
    disablePlaceholder,
    isInarticle,
    minimumFixedHeightMobile,
    minimumFixedHeightDesktop,
    maxAdHeight,
    maxAdWidth,
    ...rest
}: CombinedSlotProps) => {
    const parsedPosition = useValueAsArray(position)
    const dispatchFilledState = useCustomFilledStateDispatch(bannerKey)
    const mergedConfigs = useSlotConfigs(position, deviceProp, !!mobileOnly, !!desktopOnly, maxAdWidth, maxAdHeight)
    

    const { regular, oop } = useMemo(
        () =>
            renderOutOfPage
                ? { regular: [], oop: mergedConfigs }
                : mergedConfigs.reduce(
                      (acc, curr) => {
                          // @ts-expect-error: outOfPage might exist, too complicated to get TS to e happy with this
                          if (curr?.config?.outOfPage) {
                              acc.oop.push(curr)
                          } else {
                              acc.regular.push(curr)
                          }
                          return acc
                      },
                      { regular: [] as FullSlotConfig[], oop: [] as FullSlotConfig[] }
                  ),
        [mergedConfigs, renderOutOfPage]
    )

    const isForcedDebug = Boolean(usePersistentQueryParamValue("cypressAdsDebug"))
    const largestHeights = useMemo(()=>{
        if(!isForcedDebug) return {xs:0,md:0}
        return regular.reduce((acc,curr)=>{
            const mobilePos = curr?.mobile as AdPositionLoaded
            if(curr.hasMobileConfig && mobilePos.sizes.length > 0){
                acc.xs = Math.max(acc.xs,...mobilePos.sizes.map(s=>s[1]))
            }
            const desktopPos = curr?.desktop as AdPositionLoaded
            if(curr.hasDesktopConfig && desktopPos.sizes.length > 0){
                acc.md = Math.max(acc.md,...desktopPos.sizes.map(s=>s[1]))
            }
            return acc
        },{xs:0,md:0})
    },[regular,isForcedDebug])


    const [currentLoadedHeight, largestLoadedHeight, setLoadedAd] = useLargestAdHeight()
    const shouldSticky = currentLoadedHeight < largestLoadedHeight
    const fixedHeight = useMemo(() => {
        if (disableFixedHeight) {
            return { xs: 0, md: 0, shouldSticky: false }
        }
        const maxDesktop = Math.max(
            minimumFixedHeightDesktop || 0,
            // @ts-expect-error: if hasDesktopConfig is true, then desktop is not undefined
            ...mergedConfigs.map(c => (c.hasDesktopConfig && c?.desktop?.fixedHeight) || 0)
        )
        const maxMobile = Math.max(
            minimumFixedHeightMobile || 0,
            // @ts-expect-error: if hasMobileConfig is true, then mobile is not undefined
            ...mergedConfigs.map(c => (c.hasMobileConfig && c?.mobile?.fixedHeight) || 0)
        )
        return {
            xs: maxMobile > 0 ? maxMobile + (title ? 30 : 0) : 0,
            md: maxDesktop > 0 ? maxDesktop + (title ? 30 : 0) : 0
        }
    }, [regular, title, disableFixedHeight, minimumFixedHeightDesktop, minimumFixedHeightMobile])
    const [adsFilled, setFilledAds] = useState<boolean[]>([])
    const hasAd = adsFilled.some(filled => !!filled)
    // const [hasAd, setHasAd] = useState(false)
    const isValid = useMemo(() => {
        if (
            parsedPosition?.length === 0 ||
            mergedConfigs.filter(
                c =>
                    (desktopOnly && c.hasDesktopConfig) ||
                    (mobileOnly && c.hasMobileConfig) ||
                    (!desktopOnly && !mobileOnly && c.isValid)
            ).length === 0
        ) {
            return false
        }
        return true
    }, [mergedConfigs, parsedPosition, desktopOnly, mobileOnly])
    useEffect(() => {
        dispatchFilledState(hasAd)
        if (typeof onFilledChange === 'function') {
            onFilledChange(hasAd)
        }
    }, [hasAd, onFilledChange, dispatchFilledState])
    useRouteChangeStart(
        useCallback(() => {
            setFilledAds([])
        }, [])
    )
    if (!isValid) {
        return null
    }
    const hasTitle = !!title && !!(hasAd || !!fixedHeight)

    const regularAds =
        (regular.length > 0 && (
            <CombinedSlotStyled
                className={clsx("marginCollapse",rest.className)}
                inColumn={inColumn ? true : false}
                maxAdWidth={maxAdWidth ? maxAdWidth : undefined}
                minHeightMd={isForcedDebug?largestHeights.md:fixedHeight.md}
                minHeightXs={isForcedDebug?largestHeights.xs:fixedHeight.xs}>
                    {/* {JSON.stringify({position, adZones: regular.map(c=>c.config.adUnit)},null,2)} */}
                {hasTitle && <span className="SlotContainer_title">{title}</span>}
                <div className="SlotContainer_container">
                    <div className="SlotContainer_content">
                        {regular.map((config, index) => (
                            <Slot
                                key={`${config?.mobileAdZone} - ${config?.desktopAdZone}`}
                                config={config}
                                onAdChange={slot => {
                                    if (!slot) return
                                    setLoadedAd(slot)
                                    setFilledAds(prev => {
                                        prev[index] = !!slot?.filled
                                        return prev
                                    })
                                }}
                                isInarticle={isInarticle}
                                maxAdHeight={maxAdHeight}
                                maxAdWidth={maxAdWidth}
                            />
                        ))}
                    </div>
                    {((!hasAd && !disablePlaceholder) || isForcedDebug) && PlaceholderComponent && (
                        <div className="SlotContainer_placeholder">
                            <PlaceholderComponent {...placeholderProps} />
                        </div>
                    )}
                </div>
            </CombinedSlotStyled>
        )) ||
        null

    const regularAdsWrapped =
        isSliding && regularAds ? (
            <SlidingWrapper
                className="marginCollapse"
                enabled={!!isSliding || shouldSticky}
                offsetTop={70}
                contentHeight={currentLoadedHeight}
                frameHeight={!isSliding ? largestLoadedHeight : undefined}
                minHeightMd={fixedHeight.md}
                minHeightXs={fixedHeight.xs}
                renderInSmallSpace
                {...slidingProps}
                hasMarginBottomXs={!!fixedHeight.xs || hasAd}
                hasMarginBottomMd={!!fixedHeight.md || hasAd}>
                {regularAds}
            </SlidingWrapper>
        ) : (
            //@TODO: move this to css
            <div style={{ margin:"auto", width:"100%", marginBottom: toRem(20) }}>{regularAds}</div>
        )
    return (
        <>
            {/* Debug output, uncomment when needed */}
            {/* <span>{desktopOnly && "DESKTOP" || mobileOnly && "MOBILE" || "ANY"} - {JSON.stringify(position)}</span> */}
            {regularAdsWrapped}
            {oop.length > 0 && (
                <div style={{ overflow: 'none', position: 'relative', width: 0, height: 0 }}>
                    {oop.map(config => (
                        <Slot
                            {...rest}
                            key={`${config?.mobileAdZone} - ${config?.desktopAdZone}`}
                            config={config}
                            outOfPage
                        />
                    ))}
                </div>
            )}
        </>
    )
}

CombinedSlot.propTypes = propTypes

CombinedSlot.defaultProps = {
    ...sharedSlotDefaultProps,
    onFilledChange: undefined,
    position: undefined,
    divId: undefined,
    title: undefined,
    isSliding: false,
    slidingProps: undefined,
    inColumn: false,
    bannerKey: undefined,
    minimumFixedHeightDesktop: undefined,
    minimumFixedHeightMobile: undefined,
    disablePlaceholder: false,
    disableFixedHeight: false
}
export default withErrorBoundary(CombinedSlot, {
    FallbackComponent: () => null,
    onError: (error, componentStack) => {
        // eslint-disable-next-line no-console
        console.error('[CombinedAdSlot]: ', error, componentStack)
    }
})
