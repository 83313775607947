import { convertHexToRGBA } from '@hmn/rtl-web-core/helpers/theme'

import { toRem, toRems } from '../../../../helpers/theme'

const contentStyle = ({ theme, isEmbed, isHead, isFullscreen }) => {
    const config = {
        sm: {
            padding: toRems([0, 0, 27, 0]),
            playerContainer: {
                height: 'auto'
            },
            fixed: {
                top: 50,
                width: 350,
                minWidth: 250,
                height: 196
            }
        },
        md: {
            padding: toRems([0, 0, 38, 0]),
            playerContainer: {
                height: 'auto'
            },
            fixed: {
                top: 100,
                width: 400,
                minWidth: 250,
                height: 225
            }
        }
    }
    return {
        position: 'relative',
        padding: !isHead ? config.sm.padding : 0,
        background: theme.colors.background.default,
        [theme.breakpoints.up('md')]: {
            padding: !isHead ? config.md.padding : 0
        },
        '& .video-js': {
            '--main-player-color': theme.colors.brand,
            '--main-player-color-transparent': convertHexToRGBA(theme.colors.brand, 0.8),
            '--main-player-color-transparent-more': convertHexToRGBA(theme.colors.brand, 0.35)
        },
        '& .videoContent': {
            '&_wrapper': {
                maxWidth: isFullscreen ? '100vw' : toRem(750),
                [theme.breakpoints.up('md')]: {
                    padding: !isEmbed && !isHead ? toRems([0, 25]) : 0
                },
                margin: 'auto'
            },
            '&_playerContainer': {
                position: 'relative',
                overflow: 'hidden',
                height: isFullscreen ? '100vh' : 'auto',
                minHeight: 210,
                [theme.breakpoints.up('md')]: {
                    minWidth: toRem(700),
                    height: isFullscreen ? '100vh' : 'auto',
                    minHeight: config.md.playerContainer.height
                },

                '.VideoJs_player_container': {
                    minHeight: 100 // INFO: this is a fix for floating video, if no height is set, the video will not return to the original place
                }
            },
            '&_playerFullscreen': {
                width: '100vw',
                height: '100vh',
                '.VideoJs_player_container, .video-js-container': {
                    height: '100%',
                    width: '100%'
                }
            },
            '&_player': {
                width: toRem(900),
                height: toRem(500),
                transition: '0.2s',
                [theme.breakpoints.up('md')]: {
                    transition: '0.6s'
                },
                '@media (max-width:500px)': {
                    width: toRem(389),
                    height: toRem(218)
                },
                '@media (max-width:400px)': {
                    width: config.sm.fixed.width,
                    height: config.sm.fixed.height
                },
                '@media (max-width:370px)': {
                    width: toRem(335),
                    height: toRem(188)
                }
            },
            '&_player_fixed': {
                position: 'fixed',
                right: 10,
                top: 53,
                minWidth: 250,
                zIndex: theme.zIndex.floatingVideo,
                transition: '0.35s ease-in-out',

                '.subnav_show &': {
                    top: 89
                },

                '&:has(.vjs-vertical-ratio)': {
                    left: '50% !important',
                    width: 'min(60%, 300px) !important',
                    minWidth: 'auto',
                    // maxWidth: '180px !important',
                    height: 'auto !important',
                    translate: '-50% 0%',

                    '@media(min-width: 768px)': {
                        translate: '0% 0%',
                        left: 'auto !important',
                        right: 10
                    }
                },

                '@media (max-width:550px)': {
                    left: 12,
                    right: 0,
                    // width: '100% !important',
                    // height: 'inherit !important'
                    width: 500,
                    height: 281
                },
                '@media (max-width:500px)': {
                    left: 12,
                    right: 0,
                    // width: '100% !important',
                    // height: 'inherit !important'
                    width: 389,
                    height: 218
                },
                '@media (max-width:400px)': {
                    width: 350,
                    height: 196
                },
                '@media (max-width:370px)': {
                    width: 335,
                    height: 188
                },

                '@media(min-width: 768px)': {
                    top: 100,
                    width: 400,
                    minWidth: 250,
                    height: 225,

                    '.has_subnavigation.subnav_show &': {
                        top: 89
                    }
                }

                // [theme.breakpoints.up('md')]: {
                //     top: config.md.fixed.top,
                //     width: config.md.fixed.width,
                //     minWidth: config.md.fixed.minWidth,
                //     height: config.md.fixed.height
                // }
            },
            '&_close': {
                color: theme.colors.white,
                textDecoration: 'none',
                position: 'absolute',
                fontSize: '30px',
                lineHeight: '40px',
                width: 50,
                height: 50,
                padding: '0 10px 10px 0',
                // backgroundColor: theme.colors.white,
                zIndex: theme.zIndex.floatingVideo + 1,
                left: 0,
                top: 0,
                '&:hover': {
                    cursor: 'pointer'
                }
            },
            '&_playerMarker': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            },
            '&_head': {
                marginTop: toRem(30),
                padding: toRems([0, 15]),
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    marginTop: toRem(15)
                },
                '.title': {
                    letterSpacing: '-0.03em',
                    '&_title': {
                        fontWeight: 800
                    },
                    '&_subtitle': {
                        fontWeight: 900
                    }
                }
            },
            '&_lead': {
                marginTop: toRem(13),
                '& p': {
                    color: theme.colors.text.default,
                    fontSize: toRem(16),
                    lineHeight: 1.4
                }
            }
        },

        // .vjs-big-play-button size 60px
        '& .vjs-big-play-button.vjs-big-play-button.vjs-big-play-button': {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) scale(0.7)',

            width: '55px',
            height: '58px',
            marginLeft: 0,
            marginTop: 0,
            fontSize: '42px',
            // line-height: calc( var(--calculated-button-size)* 0.90 )
            color: '#fff',
            // background-color: var(--main-player-color)
            border: 'none',
            borderRadius: '20%',
            opacity: 0.5,
            boxShadow: '0 0 3px rgba(0,0,0,0.5)'
            // backgroundColor: 'transparent',
        },

        '& .vjs-mute-button-custom': {
            display: 'none',
            position: 'absolute',
            bottom: '8px',
            left: 0,
            zIndex: 20,
            width: '40px',
            height: '40px',
            fontFamily: 'VideoJS',
            color: theme.colors.white,

            '&:before': {
                content: '"\\f104"',
                display: 'block',
                width: '100%',
                height: '100%',
                fontSize: '22px',
                fontWeight: 400,
                lineHeight: '40px',
                textShadow: '0 0 2px rgba(0,0,0,1)'
            },

            '&.vjs-button-unmuted:before': {
                content: '"\\f107"'
            }
        },

        '& .vjs-user-inactive.vjs-playing:not(.vjs-ad-playing) ~ .vjs-mute-button-custom': {
            display: 'block',
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },

        '& .videoContent_player_fixed .vjs-mute-button-custom.vjs-mute-button-custom': {
            bottom: '5px',
            left: 0
        }
    }
}

const muteButtonColors = ({ theme }) => {
    const categoryColors = theme.colors?.category || {}

    return Object.entries(categoryColors).reduce((styles, [category, color]) => {
        styles[`.is_section_${category?.toLocaleLowerCase()} & .vjs-big-play-button.vjs-big-play-button`] = {
            backgroundColor: color
        }
        return styles
    }, {})
}

const style = props => ({ ...contentStyle(props), ...muteButtonColors(props) })

export default style
