import inViewport from 'javascript-inviewport'
import { getMIMEType } from 'node-mime-types'
import videojs from 'video.js'

import 'intersection-observer'
import videoJsAds from './VideoJsAds'
import videoJsElements from './VideoJsElements'
// eslint-disable-next-line @typescript-eslint/naming-convention
import VideoJsScheduler, { RTL_VIDEOJS_DEACTIVATE_EVENT } from './VideoJsScheduler'

type InViewOptions = Parameters<typeof inViewport>[3] & {
    autoplay?: boolean
    sticky?: boolean
}

export type RtlVideoJsOptions = {
    autoplay?: boolean
    controls?: boolean
    muted?: boolean
    aspectRatio?: string
    containerClass?: string
    stickyClass?: string
    inView?: InViewOptions
    responsive?: boolean
    fluid?: boolean
    fill?: boolean
    title?: string
    description?: string
}

type RtlVideoSource = {
    url: string
    type: string
}

// eslint-disable-next-line max-len
export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
    value !== null && value !== undefined
export const isProgressive = (url: string) => url?.includes('Manifest') || url?.includes('m3u8')
const processSource = (source: string | RtlVideoSource) => {
    if (!source) {
        return null
    }
    if (typeof source === 'string') {
        if (!source) {
            return null
        }

        const mimeType: string = (() => {
            const type = getMIMEType(source)

            if (type === 'application/octet-stream' || type?.length === 0) {
                return 'video/mp4'
            }

            return type
        })()

        const type = isProgressive(source) ? 'application/x-mpegURL' : mimeType || 'video/mp4'

        if (typeof type !== 'string') return null

        return {
            src: source,
            type
        }
    }

    if (typeof source === 'object' && source.url) {
        return {
            src: source.url,
            type: (source.type || isProgressive(source.url) ? 'application/x-mpegURL' : 'video/mp4') as string
        }
    }
    return null
}

export type RtlVideoJsCallback = (player: RtlVideoJs, ...args: unknown[]) => void

type RtlVideoProps = {
    source: string | string[] | RtlVideoSource[] | RtlVideoSource
    onReady?: () => void
    options?: Partial<RtlVideoJsOptions>
    parentContainer: HTMLElement
    vast?: any
    on?: {
        [key: string]: RtlVideoJsCallback
    }
    // 'adCanPlay', 'adStarted', 'contentPauseRequested', 'contentResumeRequested', 'click', 'videoClicked', 'videoIconClicked', 'engagedView', 'expandedChanged', 'start', 'adProgress', 'adBuffering', 'impression', 'measurable_impression', 'viewable_impression', 'fully_viewable_audible_half_duration_impression', 'overlay_resize', 'overlay_unmeasurable_impression', 'overlay_unviewable_impression', 'overlay_viewable_immediate_impression', 'overlay_viewable_end_of_session_impression', 'externalActivityEvent', 'pause', 'resume', 'firstQuartile', 'midpoint', 'thirdQuartile', 'complete', 'durationChange', 'userClose', 'userRecall', 'prefetched', 'loaded', 'allAdsCompleted', 'skip', 'skipShown', 'linearChanged', 'skippableStateChanged', 'adMetadata', 'adBreakFetchError', 'adBreakReady', 'log', 'volumeChange', 'mute', 'interaction', 'companionBackfill', 'trackingUrlPinged', 'video_card_endcap_collapse', 'video_card_endcap_dismiss', 'video_card_endcap_impression', 'companionInitialized', 'companionImpression', 'companionClick', 'mediaUrlPinged', 'loadStart', 'navigationRequested', 'adLoadError', 'adPlayError'
    onIma?: {
        [key: string]: RtlVideoJsCallback
    }
    videojsOptions?: Partial<videojs.PlayerOptions>
    id?: string
}

declare global {
    interface Window {
        google: any
    }
}
let id = 0
class RtlVideoJs {
    player: videojs.Player
    source: string | string[] | RtlVideoSource[] | RtlVideoSource
    options: RtlVideoJsOptions = {
        fill: false,
        fluid: true,
        controls: true,
        autoplay: false,
        muted: true
    }
    elements: videoJsElements
    onReady?: (player: videojs.Player) => void
    scheduler: VideoJsScheduler
    vast?: videoJsAds
    inView = false
    id: string
    inViewOptions: InViewOptions

    constructor({
        source,
        onReady,
        options,
        parentContainer,
        vast,
        on: onCallbacks,
        onIma,
        videojsOptions = {},
        id: setId
    }: RtlVideoProps) {
        const { mute: onMute, unmute: onUnmute, ...on } = onCallbacks || {}
        this.source = source
        this.id = setId || (id++).toString()
        while (window['rtl-video-id-' + this.id]) {
            this.id = this.id + (id++).toString()
        }
        window['rtl-video-id-' + this.id] = true
        if (typeof onReady === 'function') {
            this.onReady = onReady
        }
        if (options) {
            this.options = { ...this.options, ...options }
        }
        this.elements = new videoJsElements({
            parentContainer,
            classNames: {
                fixed: 'videoContent_player_fixed',
                closeButton: 'videoContent_close'
            },
            ids: { vastContainer: `vastContainer-${this.id}` },
            on: {
                close: () => {
                    this.scheduler.pause()
                }
            }
        })

        const sources: videojs.Tech.SourceObject[] = this.getSources() || []
        const {
            autoplay,
            muted,
            fluid,
            fill,
            controls // aspectRatio, containerClass, stickyClass, sticky, inView
        } = this.options

        const instanceOptions: videojs.PlayerOptions = {
            autoplay,
            muted: true,
            controls,
            fluid,
            fill,
            ...videojsOptions,
            sources,
            playsinline: true
        }
        this.player = videojs(this.elements.videoElement, instanceOptions, () => {
            this.player.muted(!!muted)
        })
        // this.player.debug(true)

        const muteButton = this.elements.muteButton
        this.player.on('volumechange', () => {
            // Adjust class names to reflect mute/unmute
            if (this.player.muted()) {
                if (typeof onMute === 'function') {
                    onMute(this)
                }
                muteButton.classList.add('vjs-button-muted')
                muteButton.classList.remove('vjs-button-unmuted')
            } else {
                if (typeof onUnmute === 'function') {
                    onUnmute(this)
                }
                muteButton.classList.add('vjs-button-unmuted')
                muteButton.classList.remove('vjs-button-muted')
            }
        })

        this.elements.muteButton.addEventListener('click', () => {
            this.player.muted(!this.player.muted())
        })

        this.player.on('touchend', e => {
            if (e.target.nodeName === 'VIDEO') {
                if (!this.isPlaying()) {
                    this.scheduler.play()
                } else {
                    this.player.pause()
                }
            }
        })

        this.scheduler = new VideoJsScheduler(this.player, { autoplay, muted })

        // this.player.on('play', () => {
        //     console.log('videojs debug', this, 'play')
        // })
        this.player.on('play', () => {
            this.triggeredAutoplay = true

            const videoWidth = this.player.videoWidth()
            const videoHeight = this.player.videoHeight()

            if (videoWidth && videoHeight) {
                const aspectClass = videoWidth > videoHeight ? 'vjs-horizontal-ratio' : 'vjs-vertical-ratio'

                const playerElement = this.player.el()

                if (playerElement) {
                    // Remove any previous aspect ratio class
                    playerElement.classList.remove('vjs-horizontal-ratio', 'vjs-vertical-ratio')
                    playerElement.classList.add(aspectClass)
                }
            }
        })

        if (vast) {
            try {
                this.vast = new videoJsAds({
                    parent: this,
                    options: vast,
                    onImaEvents: onIma,
                    autoplay
                })
            } catch (err) {
                console.log('error loading ads')
            }
        }
        this.inViewOptions = this.options.inView || {}
        const hasSticky = this.inViewOptions.sticky
        const hasInViewAutoplay = this.inViewOptions.autoplay
        if (hasSticky || hasInViewAutoplay) {
            inViewport(
                parentContainer,
                [0, 1],
                [
                    () => {
                        // console.log('player in view', { hasInViewAutoplay, autoplay }, this.elements.container)
                        this.inView = true
                        if (hasSticky) this.elements.setFixed(false)
                        if (hasInViewAutoplay) {
                            this.startAutoplay()
                        }
                    },
                    () => {
                        // console.log('out view player', { playing: this.isPlaying() }, this.elements.container)
                        this.inView = false
                        if (this.isPlaying()) {
                            if (hasSticky) {
                                this.elements.setFixed(true)
                                return
                            }

                            this.player.pause()

                            return
                        }
                        if (hasSticky) this.elements.setFixed(false)
                    }
                ],
                {
                    rootMargin: this.inViewOptions.rootMargin,
                    threshold: this.inViewOptions.threshold,
                    root: this.inViewOptions.root
                }
            )
        }
        this.player.on(RTL_VIDEOJS_DEACTIVATE_EVENT, () => {
            this.elements.setFixed(false)
        })
        this.player.one('play', () => {
            this.triggeredAutoplay = true
        })
        if (on) {
            // const onKeys = Object.keys(on)
            // onAd.push(...onKeys.filter(k => k.startsWith('onAd').map(k=>k))
            Object.keys(on).forEach(key => {
                this.player.on(key, () => {
                    on[key](this)
                })
            })
        }
    }
    triggeredAutoplay = false
    startAutoplay(force = false) {
        if (!force && (this.triggeredAutoplay || this.scheduler.checkIfAnotherPlayerIsPlaying())) {
            return
        }
        this.triggeredAutoplay = true
        this.scheduler.play()
    }

    isPlaying() {
        // console.log('player is paused', this.scheduler.isPaused())
        return !this.scheduler.isPaused()
    }

    src(source: string | string[] | RtlVideoSource[] | RtlVideoSource) {
        this.source = source
        const currentSource = this.player.src()
        const isSame =
            currentSource && (currentSource === source || (Array.isArray(source) && source[0] === currentSource))
        const sources = this.getSources()
        if (this.player && !isSame) {
            // console.log('videojs src changed', source)
            this.player.src(sources)
        }
    }
    getSources() {
        const url = this.source
        if (!url) {
            return []
        }
        if (Array.isArray(url)) {
            return url.map(processSource).filter(notEmpty)
        }
        if (typeof url === 'object') {
            return [processSource(url)].filter(notEmpty)
        }
        return [processSource(url)].filter(notEmpty)
    }
    getCurrentAd() {
        try {
            // console.log('gtmVideo: getCurrentAd', this.vast, this.vast?.getCurrentAd?.())
            return this.vast?.getCurrentAd?.()
        } catch (err) {
            return undefined
        }
    }
}
export default RtlVideoJs
